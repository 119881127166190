











































































































import moment from 'moment';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import { HomeworkFormItem } from '@/models/homework';
@Component({
  components: { Dropdown },
})
export default class HomeworkFormTemplate extends Vue {
  // Props
  @Prop()
  data!: HomeworkFormItem;

  @Prop()
  courseData!: DropdownItem[];

  @Prop()
  coursePrefixId!: number;

  @Prop()
  minimumCourseDate!: Date;

  @Prop()
  maximumCourseDate!: Date;

  // Base values
  selectedHomeworkName = ``;
  selectedStartDate: Date | null = null;
  selectedDeadline: Date | null = null;
  formattedStartDate = ``;
  formattedDeadline = ``;
  selectedCourseText = `- Select -`;
  selectedCourseId = -1;
  previousSelectedCourseId = ``;
  isAvailable = true;

  // Placeholder
  startDatePlaceholder = `DD/MM/YYYY`;
  endDatePlaceholder = `DD/MM/YYYY`;

  // UI
  get isSaveButtonDisabled() {
    return (
      this.selectedHomeworkName.length === 0 ||
      !this.selectedStartDate ||
      !this.selectedDeadline ||
      this.selectedCourseId <= 0
    );
  }

  get minimumDate() {
    return moment(this.minimumCourseDate)
      .startOf(`d`)
      .toDate();
  }

  get maximumDate() {
    return moment(this.maximumCourseDate)
      .endOf(`d`)
      .toDate();
  }

  get courses() {
    return this.courseData;
  }

  get skuNumber() {
    if (this.data && 'sku' in this.data) {
      return this.data.sku;
    }
    return null;
  }

  mounted() {
    this.updateCurrentForm();
  }

  updateCurrentForm() {
    if (this.data) {
      this.selectedHomeworkName = this.data.name;
      this.selectedStartDate = this.data.startDate;
      this.selectedDeadline = this.data.deadline;
      this.previousSelectedCourseId = this.data.courseId.toString();
      this.isAvailable = this.data.availability;
    }
  }

  //Modify Function

  updateDeadline() {
    this.formattedDeadline = moment(this.selectedDeadline)
      .endOf(`d`)
      .format(`YYYY MM DD`);
  }

  updateSelectedCourse(data: DropdownItem) {
    this.selectedCourseText = data.text;
    this.selectedCourseId = Number(data.value);

    if (
      this.selectedStartDate &&
      this.minimumDate &&
      moment(this.minimumDate).diff(this.selectedStartDate) < 0
    ) {
      this.selectedStartDate = null;
    }

    if (
      this.selectedDeadline &&
      this.maximumDate &&
      moment(this.selectedDeadline).diff(this.maximumDate) < 0
    ) {
      this.selectedDeadline = null;
    }

    this.retrieveDateRange();
  }

  @Watch(`coursePrefixId`)
  autoFillCourseSelection() {
    if (this.coursePrefixId > -1) {
      this.previousSelectedCourseId = this.coursePrefixId.toString();
    }
  }

  // Output Function
  @Emit(`save`)
  saveHomework(): HomeworkFormItem {
    return {
      ...this.data,
      name: this.selectedHomeworkName,
      startDate: this.selectedStartDate as Date,
      deadline: this.selectedDeadline as Date,
      availability: this.isAvailable,
      courseId: this.selectedCourseId,
    };
  }

  @Emit(`dates`)
  retrieveDateRange() {
    return this.selectedCourseId;
  }
}
